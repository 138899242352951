/* @import url(../../dist/fonts/inter-ui/inter.css);
@import url(../../dist/icons/themify/themify-icons.css);
@import url(../../dist/icons/font-awesome/css/font-awesome.min.css);*/
.bg-primary {
  background: #2dc5f3 !important;
  color: white !important;
}

.bg-primary-bright {
  background: #c0c5e4 !important;
}

.bg-primary-gradient {
  background: -webkit-linear-gradient(220deg, #2dc5f3, #32a8ff) !important;
  background: -moz- oldlinear-gradient(220deg, #2dc5f3, #32a8ff) !important;
  background: -o-linear-gradient(220deg, #2dc5f3, #32a8ff) !important;
  background: linear-gradient(230deg, #2dc5f3, #32a8ff) !important;
  color: white !important;
}

.bg-info {
  background: #00bcd4 !important;
  color: white !important;
}

.bg-info-bright {
  background: #e1efff !important;
}

.bg-info-gradient {
  background: -webkit-linear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: -moz- oldlinear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: -o-linear-gradient(220deg, #00bcd4, #28e4fc) !important;
  background: linear-gradient(230deg, #00bcd4, #28e4fc) !important;
  color: white !important;
}

.bg-secondary {
  background: dimgray !important;
  color: white !important;
}

.bg-secondary-bright {
  background: #ededed !important;
}

.bg-secondary-gradient {
  background: -webkit-linear-gradient(220deg, dimgray, #919191) !important;
  background: -moz- oldlinear-gradient(220deg, dimgray, #919191) !important;
  background: -o-linear-gradient(220deg, dimgray, #919191) !important;
  background: linear-gradient(230deg, dimgray, #919191) !important;
  color: white !important;
}

.bg-success {
  background: #0abb87 !important;
  color: white !important;
}

.bg-success-bright {
  background: #cef5ea !important;
}

.bg-success-gradient {
  background: -webkit-linear-gradient(220deg, #0abb87, #32e3af) !important;
  background: -moz- oldlinear-gradient(220deg, #0abb87, #32e3af) !important;
  background: -o-linear-gradient(220deg, #0abb87, #32e3af) !important;
  background: linear-gradient(230deg, #0abb87, #32e3af) !important;
  color: white !important;
}

.bg-danger {
  background: #fd397a !important;
  color: white !important;
}

.bg-danger-bright {
  background: #fcd0df !important;
}

.bg-danger-gradient {
  background: -webkit-linear-gradient(220deg, #fd397a, #ff61a2) !important;
  background: -moz- oldlinear-gradient(220deg, #fd397a, #ff61a2) !important;
  background: -o-linear-gradient(220deg, #fd397a, #ff61a2) !important;
  background: linear-gradient(230deg, #fd397a, #ff61a2) !important;
  color: white !important;
}

.bg-danger-light {
  background: #ffcfff !important;
}

.bg-warning {
  background: #ffb822 !important;
  color: white !important;
}

.bg-warning-bright {
  background: #ffe6cc !important;
}

.bg-warning-gradient {
  background: -webkit-linear-gradient(220deg, #ffb822, #ffe04a) !important;
  background: -moz- oldlinear-gradient(220deg, #ffb822, #ffe04a) !important;
  background: -o-linear-gradient(220deg, #ffb822, #ffe04a) !important;
  background: linear-gradient(230deg, #ffb822, #ffe04a) !important;
  color: white !important;
}

.bg-light {
  background: #e6e6e6 !important;
}

.bg-dark {
  background: #3a3f51 !important;
  color: white !important;
}

.bg-dark-bright {
  background: #d4d5d8 !important;
}

.bg-dark-gradient {
  background: -webkit-linear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: -moz- oldlinear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: -o-linear-gradient(220deg, #3a3f51, #585d6f) !important;
  background: linear-gradient(230deg, #3a3f51, #585d6f) !important;
  color: white !important;
}

.bg-facebook {
  background: #3b5998 !important;
  color: white !important;
}

.bg-twitter {
  background: #55acee !important;
  color: white !important;
}

.bg-linkedin {
  background: #0077b5 !important;
  color: white !important;
}

.bg-whatsapp {
  background: #43d854 !important;
  color: white !important;
}

.bg-instagram {
  background: #3f729b !important;
  color: white !important;
}

.bg-dribbble {
  background: #ea4c89 !important;
  color: white !important;
}

.bg-google {
  background: #db4437 !important;
  color: white !important;
}

.bg-youtube {
  background: #cd201f !important;
  color: white !important;
}

.text-primary {
  color: #2dc5f3 !important;
}

.text-info {
  color: #00bcd4 !important;
}

.text-success {
  color: #0abb87 !important;
}

.text-danger {
  color: #fd397a !important;
}

.text-warning {
  color: #ffb822 !important;
}

.text-light {
  color: #e6e6e6 !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-twitter {
  color: #55acee !important;
}

.text-google {
  color: #db4437 !important;
}

.text-linkedin {
  color: #0077b5 !important;
}

.text-instagram {
  color: #3f729b !important;
}

.text-whatsapp {
  color: #43d854 !important;
}

.text-dribbble {
  color: #ea4c89 !important;
}

a {
  color: #2dc5f3;
  text-decoration: none;
  -webkit-transition: color .2s;
  -o-transition: color .2s;
  -moz-transition: color .2s;
  transition: color .2s;
}

a.link-1 {
  color: black;
}

a.link-1:hover,
a.link-1:focus {
  color: #828282;
}

a.link-2 {
  color: black;
}

a.link-2:hover,
a.link-2:focus {
  color: #2dc5f3;
}

a:hover {
  text-decoration: none;
}

a:not(.active.list-group-item),
a:not(.btn):hover,
a:not(.btn):active,
a:not(.btn):focus {
  text-decoration: none !important;
  /* color: black; */
  outline: none;
}

a.btn:hover,
a.btn:active,
a.btn:focus {
  text-decoration: none !important;
}

.page-link {
  color: #2dc5f3;
}

.page-link:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.3);
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.3);
  box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.3);
}

.btn {
  font-size: 14px;
  width: auto;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 9px 14px;
  line-height: 14px;
}

.btn svg {
  width: 16px;
  height: 16px;
}

.btn[data-toggle="dropdown"] {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn.btn-sm {
  padding: 5px 10px;
  font-size: 13px;
}

.btn.btn-lg {
  padding: 15px 20px;
  font-size: 17px;
}

.btn.btn-lg svg {
  width: 22px;
  height: 22px;
}

.btn.btn-block {
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn.btn-square {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.btn.btn-rounded {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  padding: 10px 20px;
}

.btn.btn-rounded.btn-sm {
  padding: 5px 15px;
  font-size: 13px;
}

.btn.btn-rounded.btn-lg {
  padding: 20px 30px;
  font-size: 17px;
}

.btn.btn-floating {
  height: 35px;
  width: 35px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.btn.btn-floating.btn-sm {
  height: 30px;
  width: 30px;
}

.btn.btn-floating.btn-lg {
  height: 50px;
  width: 50px;
}

.btn.btn-uppercase {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
}

.btn.btn-uppercase.btn-sm {
  font-size: 11px;
}

.btn.btn-uppercase.btn-lg {
  font-size: 14px;
}

.btn.btn-shadow {
  -webkit-box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 4px 1px rgba(0, 0, 0, 0.3);
}

.btn.btn-shadow:focus,
.btn.btn-shadow:active {
  -webkit-box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.3) !important;
  -moz-box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.3) !important;
}

.btn.btn-primary,
a.btn[href="#next"],
a.btn[href="#previous"] {
  background: #2dc5f3;
  border-color: #2dc5f3;
}

.btn.btn-primary:not(:disabled):not(.disabled):hover,
a.btn:not(:disabled):not(.disabled):hover[href="#next"],
a.btn:not(:disabled):not(.disabled):hover[href="#previous"],
.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href="#next"],
a.btn:not(:disabled):not(.disabled):focus[href="#previous"],
.btn.btn-primary:not(:disabled):not(.disabled):active,
a.btn:not(:disabled):not(.disabled):active[href="#next"],
a.btn:not(:disabled):not(.disabled):active[href="#previous"] {
  background: #0062e1;
  border-color: #0062e1;
}

.btn.btn-primary:not(:disabled):not(.disabled):focus,
a.btn:not(:disabled):not(.disabled):focus[href="#next"],
a.btn:not(:disabled):not(.disabled):focus[href="#previous"] {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.4) !important;
}

.btn.btn-primary.btn-pulse:not(:disabled):not(.disabled),
a.btn.btn-pulse:not(:disabled):not(.disabled)[href="#next"],
a.btn.btn-pulse:not(:disabled):not(.disabled)[href="#previous"] {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-primary:hover,
a.btn:hover[href="#next"],
a.btn:hover[href="#previous"] {
  -webkit-animation: none;
}

.btn.btn-gradient-primary {
  background: -webkit-linear-gradient(70deg, #32a8ff, #0071f0);
  background: -moz- oldlinear-gradient(70deg, #32a8ff, #0071f0);
  background: -o-linear-gradient(70deg, #32a8ff, #0071f0);
  background: linear-gradient(20deg, #32a8ff, #0071f0);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-primary:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-primary:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-primary:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, #2dc5f3, #0071f0);
  background: -moz- oldlinear-gradient(70deg, #2dc5f3, #0071f0);
  background: -o-linear-gradient(70deg, #2dc5f3, #0071f0);
  background: linear-gradient(20deg, #2dc5f3, #0071f0);
  border-color: transparent;
}

.btn.btn-gradient-primary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.5);
}

.btn.btn-gradient-primary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-primary:hover {
  -webkit-animation: none;
}

.btn.btn-light-primary {
  background: rgba(10, 128, 255, 0.3);
  border-color: transparent;
  color: #004ecd;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):hover,
.btn.btn-light-primary:not(:disabled):not(.disabled):focus,
.btn.btn-light-primary:not(:disabled):not(.disabled):active {
  background: rgba(10, 128, 255, 0.5);
  border-color: transparent;
}

.btn.btn-light-primary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.2);
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.2);
}

.btn.btn-light-primary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-primary:hover {
  -webkit-animation: none;
}

.btn.btn-outline-primary {
  background: none;
  border-color: #2dc5f3;
  color: #0076f5;
  border-width: 1px;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):hover {
  background: #2dc5f3;
  border-color: #2dc5f3;
  color: white;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):focus,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active {
  background: #0062e1;
  border-color: #0062e1;
  color: white;
}

.btn.btn-outline-primary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(10, 128, 255, 0.4);
}

.btn.btn-outline-primary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 128, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-primary:hover {
  -webkit-animation: none;
}

.btn.btn-secondary {
  background: dimgray;
  border-color: dimgray;
}

.btn.btn-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-secondary:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
}

.btn.btn-secondary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4) !important;
}

.btn.btn-secondary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-secondary {
  background: -webkit-linear-gradient(70deg, #919191, #5a5a5a);
  background: -moz- oldlinear-gradient(70deg, #919191, #5a5a5a);
  background: -o-linear-gradient(70deg, #919191, #5a5a5a);
  background: linear-gradient(20deg, #919191, #5a5a5a);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-secondary:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, dimgray, #5a5a5a);
  background: -moz- oldlinear-gradient(70deg, dimgray, #5a5a5a);
  background: -o-linear-gradient(70deg, dimgray, #5a5a5a);
  background: linear-gradient(20deg, dimgray, #5a5a5a);
  border-color: transparent;
}

.btn.btn-gradient-secondary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.5);
}

.btn.btn-gradient-secondary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-light-secondary {
  background: rgba(105, 105, 105, 0.3);
  border-color: transparent;
  color: #373737;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):hover,
.btn.btn-light-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-light-secondary:not(:disabled):not(.disabled):active {
  background: rgba(105, 105, 105, 0.5);
  border-color: transparent;
}

.btn.btn-light-secondary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.2);
  -moz-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.2);
}

.btn.btn-light-secondary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-outline-secondary {
  background: none;
  border-color: dimgray;
  color: #5f5f5f;
  border-width: 1px;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  background: dimgray;
  border-color: dimgray;
  color: white;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):focus,
.btn.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background: #4b4b4b;
  border-color: #4b4b4b;
  color: white;
}

.btn.btn-outline-secondary:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(105, 105, 105, 0.4);
}

.btn.btn-outline-secondary.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(105, 105, 105, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-secondary:hover {
  -webkit-animation: none;
}

.btn.btn-success {
  background: #0abb87;
  border-color: #0abb87;
}

.btn.btn-success:not(:disabled):not(.disabled):hover,
.btn.btn-success:not(:disabled):not(.disabled):focus,
.btn.btn-success:not(:disabled):not(.disabled):active {
  background: #009d69;
  border-color: #009d69;
}

.btn.btn-success:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4) !important;
}

.btn.btn-success.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-success:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-success {
  background: -webkit-linear-gradient(70deg, #32e3af, #00ac78);
  background: -moz- oldlinear-gradient(70deg, #32e3af, #00ac78);
  background: -o-linear-gradient(70deg, #32e3af, #00ac78);
  background: linear-gradient(20deg, #32e3af, #00ac78);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-success:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-success:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-success:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, #0abb87, #00ac78);
  background: -moz- oldlinear-gradient(70deg, #0abb87, #00ac78);
  background: -o-linear-gradient(70deg, #0abb87, #00ac78);
  background: linear-gradient(20deg, #0abb87, #00ac78);
  border-color: transparent;
}

.btn.btn-gradient-success:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.5);
}

.btn.btn-gradient-success.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-success:hover {
  -webkit-animation: none;
}

.btn.btn-light-success {
  background: rgba(10, 187, 135, 0.3);
  border-color: transparent;
  color: #008955;
}

.btn.btn-light-success:not(:disabled):not(.disabled):hover,
.btn.btn-light-success:not(:disabled):not(.disabled):focus,
.btn.btn-light-success:not(:disabled):not(.disabled):active {
  background: rgba(10, 187, 135, 0.5);
  border-color: transparent;
}

.btn.btn-light-success:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.2);
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.2);
}

.btn.btn-light-success.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-success:hover {
  -webkit-animation: none;
}

.btn.btn-outline-success {
  background: none;
  border-color: #0abb87;
  color: #00b17d;
  border-width: 1px;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):hover {
  background: #0abb87;
  border-color: #0abb87;
  color: white;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):focus,
.btn.btn-outline-success:not(:disabled):not(.disabled):active {
  background: #009d69;
  border-color: #009d69;
  color: white;
}

.btn.btn-outline-success:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(10, 187, 135, 0.4);
}

.btn.btn-outline-success.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(10, 187, 135, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-success:hover {
  -webkit-animation: none;
}

.btn.btn-danger {
  background: #fd397a;
  border-color: #fd397a;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover,
.btn.btn-danger:not(:disabled):not(.disabled):focus,
.btn.btn-danger:not(:disabled):not(.disabled):active {
  background: #df1b5c;
  border-color: #df1b5c;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.4) !important;
}

.btn.btn-danger.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-danger:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-danger {
  background: -webkit-linear-gradient(70deg, #ff61a2, #ee2a6b);
  background: -moz- oldlinear-gradient(70deg, #ff61a2, #ee2a6b);
  background: -o-linear-gradient(70deg, #ff61a2, #ee2a6b);
  background: linear-gradient(20deg, #ff61a2, #ee2a6b);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-danger:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-danger:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-danger:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, #fd397a, #ee2a6b);
  background: -moz- oldlinear-gradient(70deg, #fd397a, #ee2a6b);
  background: -o-linear-gradient(70deg, #fd397a, #ee2a6b);
  background: linear-gradient(20deg, #fd397a, #ee2a6b);
  border-color: transparent;
}

.btn.btn-gradient-danger:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.5);
}

.btn.btn-gradient-danger.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-danger:hover {
  -webkit-animation: none;
}

.btn.btn-light-danger {
  background: rgba(253, 57, 122, 0.3);
  border-color: transparent;
  color: #cb0748;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):hover,
.btn.btn-light-danger:not(:disabled):not(.disabled):focus,
.btn.btn-light-danger:not(:disabled):not(.disabled):active {
  background: rgba(253, 57, 122, 0.5);
  border-color: transparent;
}

.btn.btn-light-danger:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.2);
  -moz-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.2);
}

.btn.btn-light-danger.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-danger:hover {
  -webkit-animation: none;
}

.btn.btn-outline-danger {
  background: none;
  border-color: #fd397a;
  color: #f32f70;
  border-width: 1px;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):hover {
  background: #fd397a;
  border-color: #fd397a;
  color: white;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):focus,
.btn.btn-outline-danger:not(:disabled):not(.disabled):active {
  background: #df1b5c;
  border-color: #df1b5c;
  color: white;
}

.btn.btn-outline-danger:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(253, 57, 122, 0.4);
}

.btn.btn-outline-danger.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(253, 57, 122, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-danger:hover {
  -webkit-animation: none;
}

.btn.btn-warning {
  background: #ffb822;
  border-color: #ffb822;
}

.btn.btn-warning:not(:disabled):not(.disabled):hover,
.btn.btn-warning:not(:disabled):not(.disabled):focus,
.btn.btn-warning:not(:disabled):not(.disabled):active {
  background: #e19a04;
  border-color: #e19a04;
}

.btn.btn-warning:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.4) !important;
}

.btn.btn-warning.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-warning:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-warning {
  background: -webkit-linear-gradient(70deg, #ffe04a, #f0a913);
  background: -moz- oldlinear-gradient(70deg, #ffe04a, #f0a913);
  background: -o-linear-gradient(70deg, #ffe04a, #f0a913);
  background: linear-gradient(20deg, #ffe04a, #f0a913);
  border-color: transparent;
  color: white;
  color: #212529;
}

.btn.btn-gradient-warning:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-warning:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-warning:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, #ffb822, #f0a913);
  background: -moz- oldlinear-gradient(70deg, #ffb822, #f0a913);
  background: -o-linear-gradient(70deg, #ffb822, #f0a913);
  background: linear-gradient(20deg, #ffb822, #f0a913);
  border-color: transparent;
}

.btn.btn-gradient-warning:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.5);
}

.btn.btn-gradient-warning.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-warning:hover {
  -webkit-animation: none;
}

.btn.btn-light-warning {
  background: rgba(255, 184, 34, 0.3);
  border-color: transparent;
  color: #cd8600;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):hover,
.btn.btn-light-warning:not(:disabled):not(.disabled):focus,
.btn.btn-light-warning:not(:disabled):not(.disabled):active {
  background: rgba(255, 184, 34, 0.5);
  border-color: transparent;
}

.btn.btn-light-warning:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.2);
  -moz-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.2);
}

.btn.btn-light-warning.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-warning:hover {
  -webkit-animation: none;
}

.btn.btn-outline-warning {
  background: none;
  border-color: #ffb822;
  color: #f5ae18;
  border-width: 1px;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):hover {
  background: #ffb822;
  border-color: #ffb822;
  color: white;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):focus,
.btn.btn-outline-warning:not(:disabled):not(.disabled):active {
  background: #e19a04;
  border-color: #e19a04;
  color: white;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(255, 184, 34, 0.4);
}

.btn.btn-outline-warning.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(255, 184, 34, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-warning:hover {
  -webkit-animation: none;
}

.btn.btn-outline-warning:not(:disabled):not(.disabled):hover,
.btn.btn-outline-warning:not(:disabled):not(.disabled):focus,
.btn.btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #212529;
}

.btn.btn-info {
  background: #00bcd4;
  border-color: #00bcd4;
}

.btn.btn-info:not(:disabled):not(.disabled):hover,
.btn.btn-info:not(:disabled):not(.disabled):focus,
.btn.btn-info:not(:disabled):not(.disabled):active {
  background: #009eb6;
  border-color: #009eb6;
}

.btn.btn-info:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4) !important;
}

.btn.btn-info.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-info:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-info {
  background: -webkit-linear-gradient(70deg, #28e4fc, #00adc5);
  background: -moz- oldlinear-gradient(70deg, #28e4fc, #00adc5);
  background: -o-linear-gradient(70deg, #28e4fc, #00adc5);
  background: linear-gradient(20deg, #28e4fc, #00adc5);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-info:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-info:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-info:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, #00bcd4, #00adc5);
  background: -moz- oldlinear-gradient(70deg, #00bcd4, #00adc5);
  background: -o-linear-gradient(70deg, #00bcd4, #00adc5);
  background: linear-gradient(20deg, #00bcd4, #00adc5);
  border-color: transparent;
}

.btn.btn-gradient-info:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}

.btn.btn-gradient-info.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-info:hover {
  -webkit-animation: none;
}

.btn.btn-light-info {
  background: rgba(0, 188, 212, 0.3);
  border-color: transparent;
  color: #008aa2;
}

.btn.btn-light-info:not(:disabled):not(.disabled):hover,
.btn.btn-light-info:not(:disabled):not(.disabled):focus,
.btn.btn-light-info:not(:disabled):not(.disabled):active {
  background: rgba(0, 188, 212, 0.5);
  border-color: transparent;
}

.btn.btn-light-info:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.2);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.2);
}

.btn.btn-light-info.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-info:hover {
  -webkit-animation: none;
}

.btn.btn-outline-info {
  background: none;
  border-color: #00bcd4;
  color: #00b2ca;
  border-width: 1px;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):hover {
  background: #00bcd4;
  border-color: #00bcd4;
  color: white;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):focus,
.btn.btn-outline-info:not(:disabled):not(.disabled):active {
  background: #009eb6;
  border-color: #009eb6;
  color: white;
}

.btn.btn-outline-info:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.4);
}

.btn.btn-outline-info.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 188, 212, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-info:hover {
  -webkit-animation: none;
}

.btn.btn-light {
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.btn.btn-light:not(:disabled):not(.disabled):hover,
.btn.btn-light:not(:disabled):not(.disabled):focus,
.btn.btn-light:not(:disabled):not(.disabled):active {
  background: #c8c8c8;
  border-color: #c8c8c8;
}

.btn.btn-light:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4) !important;
}

.btn.btn-light.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-light {
  background: -webkit-linear-gradient(70deg, white, #d7d7d7);
  background: -moz- oldlinear-gradient(70deg, white, #d7d7d7);
  background: -o-linear-gradient(70deg, white, #d7d7d7);
  background: linear-gradient(20deg, white, #d7d7d7);
  border-color: transparent;
  color: white;
  color: inherit;
}

.btn.btn-gradient-light:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-light:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-light:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, #e6e6e6, #d7d7d7);
  background: -moz- oldlinear-gradient(70deg, #e6e6e6, #d7d7d7);
  background: -o-linear-gradient(70deg, #e6e6e6, #d7d7d7);
  background: linear-gradient(20deg, #e6e6e6, #d7d7d7);
  border-color: transparent;
}

.btn.btn-gradient-light:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.5);
}

.btn.btn-gradient-light.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-light:hover {
  -webkit-animation: none;
}

.btn.btn-outline-light {
  background: none;
  border-color: #e6e6e6;
  color: gainsboro;
  border-width: 1px;
  color: #212529;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):hover {
  background: #e6e6e6;
  border-color: #e6e6e6;
  color: white;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):focus,
.btn.btn-outline-light:not(:disabled):not(.disabled):active {
  background: #c8c8c8;
  border-color: #c8c8c8;
  color: white;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.4);
}

.btn.btn-outline-light.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(230, 230, 230, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-light:hover {
  -webkit-animation: none;
}

.btn.btn-outline-light:not(:disabled):not(.disabled):hover,
.btn.btn-outline-light:not(:disabled):not(.disabled):focus,
.btn.btn-outline-light:not(:disabled):not(.disabled):active {
  color: #212529;
}

.btn.btn-dark {
  background: #3a3f51;
  border-color: #3a3f51;
}

.btn.btn-dark:not(:disabled):not(.disabled):hover,
.btn.btn-dark:not(:disabled):not(.disabled):focus,
.btn.btn-dark:not(:disabled):not(.disabled):active {
  background: #1c2133;
  border-color: #1c2133;
}

.btn.btn-dark:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4) !important;
}

.btn.btn-dark.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-dark:hover {
  -webkit-animation: none;
}

.btn.btn-gradient-dark {
  background: -webkit-linear-gradient(70deg, #626779, #2b3042);
  background: -moz- oldlinear-gradient(70deg, #626779, #2b3042);
  background: -o-linear-gradient(70deg, #626779, #2b3042);
  background: linear-gradient(20deg, #626779, #2b3042);
  border-color: transparent;
  color: white;
}

.btn.btn-gradient-dark:not(:disabled):not(.disabled):hover,
.btn.btn-gradient-dark:not(:disabled):not(.disabled):focus,
.btn.btn-gradient-dark:not(:disabled):not(.disabled):active {
  background: -webkit-linear-gradient(70deg, #3a3f51, #2b3042);
  background: -moz- oldlinear-gradient(70deg, #3a3f51, #2b3042);
  background: -o-linear-gradient(70deg, #3a3f51, #2b3042);
  background: linear-gradient(20deg, #3a3f51, #2b3042);
  border-color: transparent;
}

.btn.btn-gradient-dark:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5);
  -moz-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5);
}

.btn.btn-gradient-dark.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-gradient-dark:hover {
  -webkit-animation: none;
}

.btn.btn-light-dark {
  background: rgba(58, 63, 81, 0.3);
  border-color: transparent;
  color: #080d1f;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):hover,
.btn.btn-light-dark:not(:disabled):not(.disabled):focus,
.btn.btn-light-dark:not(:disabled):not(.disabled):active {
  background: rgba(58, 63, 81, 0.5);
  border-color: transparent;
}

.btn.btn-light-dark:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.2);
  -moz-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.2);
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.2);
}

.btn.btn-light-dark.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-light-dark:hover {
  -webkit-animation: none;
}

.btn.btn-outline-dark {
  background: none;
  border-color: #3a3f51;
  color: #303547;
  border-width: 1px;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):hover {
  background: #3a3f51;
  border-color: #3a3f51;
  color: white;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):focus,
.btn.btn-outline-dark:not(:disabled):not(.disabled):active {
  background: #1c2133;
  border-color: #1c2133;
  color: white;
}

.btn.btn-outline-dark:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.4);
}

.btn.btn-outline-dark.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(58, 63, 81, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-dark:hover {
  -webkit-animation: none;
}

.btn.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: white;
}

.btn.btn-facebook:not(:disabled):not(.disabled):hover,
.btn.btn-facebook:not(:disabled):not(.disabled):focus,
.btn.btn-facebook:not(:disabled):not(.disabled):active {
  background: #1d3b7a;
  border-color: #1d3b7a;
}

.btn.btn-facebook:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4) !important;
}

.btn.btn-facebook.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-facebook:hover {
  -webkit-animation: none;
}

.btn.btn-outline-facebook {
  background: none;
  border-color: #3b5998;
  color: #314f8e;
  border-width: 1px;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):hover {
  background: #3b5998;
  border-color: #3b5998;
  color: white;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):focus,
.btn.btn-outline-facebook:not(:disabled):not(.disabled):active {
  background: #1d3b7a;
  border-color: #1d3b7a;
  color: white;
}

.btn.btn-outline-facebook:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.4);
}

.btn.btn-outline-facebook.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(59, 89, 152, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-facebook:hover {
  -webkit-animation: none;
}

.btn.btn-google {
  background: #db4437;
  border-color: #db4437;
  color: white;
}

.btn.btn-google:not(:disabled):not(.disabled):hover,
.btn.btn-google:not(:disabled):not(.disabled):focus,
.btn.btn-google:not(:disabled):not(.disabled):active {
  background: #bd2619;
  border-color: #bd2619;
}

.btn.btn-google:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4) !important;
}

.btn.btn-google.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-google:hover {
  -webkit-animation: none;
}

.btn.btn-outline-google {
  background: none;
  border-color: #db4437;
  color: #d13a2d;
  border-width: 1px;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):hover {
  background: #db4437;
  border-color: #db4437;
  color: white;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):focus,
.btn.btn-outline-google:not(:disabled):not(.disabled):active {
  background: #bd2619;
  border-color: #bd2619;
  color: white;
}

.btn.btn-outline-google:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(219, 68, 55, 0.4);
}

.btn.btn-outline-google.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(219, 68, 55, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-google:hover {
  -webkit-animation: none;
}

.btn.btn-twitter {
  background: #55acee;
  border-color: #55acee;
  color: white;
}

.btn.btn-twitter:not(:disabled):not(.disabled):hover,
.btn.btn-twitter:not(:disabled):not(.disabled):focus,
.btn.btn-twitter:not(:disabled):not(.disabled):active {
  background: #378ed0;
  border-color: #378ed0;
}

.btn.btn-twitter:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4) !important;
}

.btn.btn-twitter.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-twitter:hover {
  -webkit-animation: none;
}

.btn.btn-outline-twitter {
  background: none;
  border-color: #55acee;
  color: #4ba2e4;
  border-width: 1px;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):hover {
  background: #55acee;
  border-color: #55acee;
  color: white;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):focus,
.btn.btn-outline-twitter:not(:disabled):not(.disabled):active {
  background: #378ed0;
  border-color: #378ed0;
  color: white;
}

.btn.btn-outline-twitter:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(85, 172, 238, 0.4);
}

.btn.btn-outline-twitter.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(85, 172, 238, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-twitter:hover {
  -webkit-animation: none;
}

.btn.btn-linkedin {
  background: #0077b5;
  border-color: #0077b5;
  color: white;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):hover,
.btn.btn-linkedin:not(:disabled):not(.disabled):focus,
.btn.btn-linkedin:not(:disabled):not(.disabled):active {
  background: #005997;
  border-color: #005997;
}

.btn.btn-linkedin:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4) !important;
}

.btn.btn-linkedin.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-linkedin:hover {
  -webkit-animation: none;
}

.btn.btn-outline-linkedin {
  background: none;
  border-color: #0077b5;
  color: #006dab;
  border-width: 1px;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):hover {
  background: #0077b5;
  border-color: #0077b5;
  color: white;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):focus,
.btn.btn-outline-linkedin:not(:disabled):not(.disabled):active {
  background: #005997;
  border-color: #005997;
  color: white;
}

.btn.btn-outline-linkedin:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 181, 0.4);
}

.btn.btn-outline-linkedin.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 119, 181, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-linkedin:hover {
  -webkit-animation: none;
}

.btn.btn-whatsapp {
  background: #43d854;
  border-color: #43d854;
  color: white;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):hover,
.btn.btn-whatsapp:not(:disabled):not(.disabled):focus,
.btn.btn-whatsapp:not(:disabled):not(.disabled):active {
  background: #25ba36;
  border-color: #25ba36;
}

.btn.btn-whatsapp:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4) !important;
}

.btn.btn-whatsapp.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-whatsapp:hover {
  -webkit-animation: none;
}

.btn.btn-outline-whatsapp {
  background: none;
  border-color: #43d854;
  color: #39ce4a;
  border-width: 1px;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):hover {
  background: #43d854;
  border-color: #43d854;
  color: white;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):focus,
.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):active {
  background: #25ba36;
  border-color: #25ba36;
  color: white;
}

.btn.btn-outline-whatsapp:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(67, 216, 84, 0.4);
}

.btn.btn-outline-whatsapp.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(67, 216, 84, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-whatsapp:hover {
  -webkit-animation: none;
}

.btn.btn-instagram {
  background: #3f729b;
  border-color: #3f729b;
  color: white;
}

.btn.btn-instagram:not(:disabled):not(.disabled):hover,
.btn.btn-instagram:not(:disabled):not(.disabled):focus,
.btn.btn-instagram:not(:disabled):not(.disabled):active {
  background: #21547d;
  border-color: #21547d;
}

.btn.btn-instagram:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4) !important;
}

.btn.btn-instagram.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-instagram:hover {
  -webkit-animation: none;
}

.btn.btn-outline-instagram {
  background: none;
  border-color: #3f729b;
  color: #356891;
  border-width: 1px;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):hover {
  background: #3f729b;
  border-color: #3f729b;
  color: white;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):focus,
.btn.btn-outline-instagram:not(:disabled):not(.disabled):active {
  background: #21547d;
  border-color: #21547d;
  color: white;
}

.btn.btn-outline-instagram:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(63, 114, 155, 0.4);
}

.btn.btn-outline-instagram.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(63, 114, 155, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-instagram:hover {
  -webkit-animation: none;
}

.btn.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: white;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):hover,
.btn.btn-dribbble:not(:disabled):not(.disabled):focus,
.btn.btn-dribbble:not(:disabled):not(.disabled):active {
  background: #cc2e6b;
  border-color: #cc2e6b;
}

.btn.btn-dribbble:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4) !important;
}

.btn.btn-dribbble.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-dribbble:hover {
  -webkit-animation: none;
}

.btn.btn-outline-dribbble {
  background: none;
  border-color: #ea4c89;
  color: #e0427f;
  border-width: 1px;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):hover {
  background: #ea4c89;
  border-color: #ea4c89;
  color: white;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):focus,
.btn.btn-outline-dribbble:not(:disabled):not(.disabled):active {
  background: #cc2e6b;
  border-color: #cc2e6b;
  color: white;
}

.btn.btn-outline-dribbble:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.4);
}

.btn.btn-outline-dribbble.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(234, 76, 137, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-dribbble:hover {
  -webkit-animation: none;
}

.btn.btn-youtube {
  background: #cd201f;
  border-color: #cd201f;
  color: white;
}

.btn.btn-youtube:not(:disabled):not(.disabled):hover,
.btn.btn-youtube:not(:disabled):not(.disabled):focus,
.btn.btn-youtube:not(:disabled):not(.disabled):active {
  background: #af0201;
  border-color: #af0201;
}

.btn.btn-youtube:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4) !important;
}

.btn.btn-youtube.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-youtube:hover {
  -webkit-animation: none;
}

.btn.btn-outline-youtube {
  background: none;
  border-color: #cd201f;
  color: #c31615;
  border-width: 1px;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):hover {
  background: #cd201f;
  border-color: #cd201f;
  color: white;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):focus,
.btn.btn-outline-youtube:not(:disabled):not(.disabled):active {
  background: #af0201;
  border-color: #af0201;
  color: white;
}

.btn.btn-outline-youtube:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(205, 32, 31, 0.4);
}

.btn.btn-outline-youtube.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(205, 32, 31, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-youtube:hover {
  -webkit-animation: none;
}

.btn.btn-github {
  background: #00405d;
  border-color: #00405d;
  color: white;
}

.btn.btn-github:not(:disabled):not(.disabled):hover,
.btn.btn-github:not(:disabled):not(.disabled):focus,
.btn.btn-github:not(:disabled):not(.disabled):active {
  background: #00223f;
  border-color: #00223f;
}

.btn.btn-github:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4) !important;
}

.btn.btn-github.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-github:hover {
  -webkit-animation: none;
}

.btn.btn-outline-github {
  background: none;
  border-color: #00405d;
  color: #003653;
  border-width: 1px;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):hover {
  background: #00405d;
  border-color: #00405d;
  color: white;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):focus,
.btn.btn-outline-github:not(:disabled):not(.disabled):active {
  background: #00223f;
  border-color: #00223f;
  color: white;
}

.btn.btn-outline-github:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(0, 64, 93, 0.4);
}

.btn.btn-outline-github.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 64, 93, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-github:hover {
  -webkit-animation: none;
}

.btn.btn-behance {
  background: #1769ff;
  border-color: #1769ff;
  color: white;
}

.btn.btn-behance:not(:disabled):not(.disabled):hover,
.btn.btn-behance:not(:disabled):not(.disabled):focus,
.btn.btn-behance:not(:disabled):not(.disabled):active {
  background: #004be1;
  border-color: #004be1;
}

.btn.btn-behance:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4) !important;
}

.btn.btn-behance.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-behance:hover {
  -webkit-animation: none;
}

.btn.btn-outline-behance {
  background: none;
  border-color: #1769ff;
  color: #0d5ff5;
  border-width: 1px;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):hover {
  background: #1769ff;
  border-color: #1769ff;
  color: white;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):focus,
.btn.btn-outline-behance:not(:disabled):not(.disabled):active {
  background: #004be1;
  border-color: #004be1;
  color: white;
}

.btn.btn-outline-behance:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.4);
}

.btn.btn-outline-behance.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(23, 105, 255, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-behance:hover {
  -webkit-animation: none;
}

.btn.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: white;
}

.btn.btn-skype:not(:disabled):not(.disabled):hover,
.btn.btn-skype:not(:disabled):not(.disabled):focus,
.btn.btn-skype:not(:disabled):not(.disabled):active {
  background: #0091d2;
  border-color: #0091d2;
}

.btn.btn-skype:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4) !important;
}

.btn.btn-skype.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-skype:hover {
  -webkit-animation: none;
}

.btn.btn-outline-skype {
  background: none;
  border-color: #00aff0;
  color: #00a5e6;
  border-width: 1px;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):hover {
  background: #00aff0;
  border-color: #00aff0;
  color: white;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):focus,
.btn.btn-outline-skype:not(:disabled):not(.disabled):active {
  background: #0091d2;
  border-color: #0091d2;
  color: white;
}

.btn.btn-outline-skype:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(0, 175, 240, 0.4);
}

.btn.btn-outline-skype.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-skype:hover {
  -webkit-animation: none;
}

.btn.btn-yahoo {
  background: #410093;
  border-color: #410093;
  color: white;
}

.btn.btn-yahoo:not(:disabled):not(.disabled):hover,
.btn.btn-yahoo:not(:disabled):not(.disabled):focus,
.btn.btn-yahoo:not(:disabled):not(.disabled):active {
  background: #230075;
  border-color: #230075;
}

.btn.btn-yahoo:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4) !important;
  -moz-box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4) !important;
  box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4) !important;
}

.btn.btn-yahoo.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-yahoo:hover {
  -webkit-animation: none;
}

.btn.btn-outline-yahoo {
  background: none;
  border-color: #410093;
  color: #370089;
  border-width: 1px;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):hover {
  background: #410093;
  border-color: #410093;
  color: white;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):focus,
.btn.btn-outline-yahoo:not(:disabled):not(.disabled):active {
  background: #230075;
  border-color: #230075;
  color: white;
}

.btn.btn-outline-yahoo:not(:disabled):not(.disabled):focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4);
  -moz-box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(65, 0, 147, 0.4);
}

.btn.btn-outline-yahoo.btn-pulse:not(:disabled):not(.disabled) {
  -webkit-box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -moz-box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  box-shadow: 0 0 0 0 rgba(65, 0, 147, 0.9) !important;
  -webkit-animation: pulse 1.5s infinite !important;
}

.btn.btn-outline-yahoo:hover {
  -webkit-animation: none;
}

.btn.btn-apple,
.btn.btn-google-play {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.btn.btn-apple img,
.btn.btn-google-play img {
  width: 35px;
  margin-right: 10px;
}

.btn.btn-apple i,
.btn.btn-google-play i {
  font-size: 40px;
  margin-right: 10px;
}

.btn.btn-apple>span,
.btn.btn-google-play>span {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
}

.btn.btn-apple>span span:nth-child(2),
.btn.btn-google-play>span span:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  margin-top: 5px;
}

.btn.btn-apple:hover,
.btn.btn-apple:active,
.btn.btn-apple:focus,
.btn.btn-google-play:hover,
.btn.btn-google-play:active,
.btn.btn-google-play:focus {
  background: #040507;
  color: white;
}

.btn.btn-apple {
  border: 1px solid #040507;
  color: #040507;
}

.btn.btn-google-play {
  background: #040507;
  color: white;
}

.btn.btn-google-play>span span:nth-child(1) {
  text-transform: uppercase;
  font-size: 12px;
}

@-webkit-keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulse {
  to {
    -moz-box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-o-keyframes pulse {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@keyframes pulse {
  to {
    -webkit-box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    -moz-box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

body {
  background-color: white;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

body:not(.form-membership) {
  overflow: hidden;
}

.btn {
  border-color: transparent;
}

.btn-primary,
a[href="#next"],
a[href="#previous"] {
  background-color: #2dc5f3;
}

a {
  color: #2dc5f3;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: #0062e1;
}

.form-control {
  font-size: 14px;
}

.page-loading {
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  background-color: white;
  z-index: 999;
}

.btn-icon {
  margin-right: .5rem;
}

.list-group-item {
  background-color: inherit;
  border-color: #ebebeb;
}

ul.list-inline .list-inline-item {
  margin-bottom: .5rem;
}

ul.list-inline .list-inline-item:not(:last-child) {
  margin-right: 0;
}

ul.list-inline:not(.social-links) .list-inline-item {
  margin-left: .5rem;
}

ul.list-inline.social-links .list-inline-item {
  margin-right: .5rem;
  margin-bottom: .6rem;
}

.files ul.list-inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
}

.files ul.list-inline .list-inline-item {
  margin-left: 0;
  margin-right: .5rem;
}

.connection-error svg {
  margin: 30px 0;
  height: 100px;
  width: 100%;
}

.connection-error svg path,
.connection-error svg circle,
.connection-error svg polygon {
  fill: #fd397a;
}

.form-control {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #2dc5f3 !important;
}

.custom-control-label {
  line-height: 25px;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #2dc5f3;
  border-color: #2dc5f3;
}

.sticky-top {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.dropdown-menu {
  border-color: #ebebeb;
  -webkit-box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 20px -10px;
  -moz-box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 20px -10px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 0px 20px -10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
}

.dropdown-menu .dropdown-item.active {
  background: none;
  color: inherit;
}

.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active {
  background-color: #e6e6e6;
  color: black;
}

figure.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 2.3rem;
  width: 2.3rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

figure.avatar .avatar-title {
  color: rgba(255, 255, 255, 0.8);
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 19px;
}

figure.avatar>a {
  width: 100%;
  height: 100%;
  display: block;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  -moz-transition: color .3s;
  transition: color .3s;
  color: #2dc5f3;
}

figure.avatar>a>img,
figure.avatar>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

figure.avatar.avatar-sm {
  height: 1.3rem;
  width: 1.3rem;
}

figure.avatar.avatar-sm .avatar-title {
  font-size: 14px;
}

figure.avatar.avatar-sm.avatar-state-primary:before,
figure.avatar.avatar-sm.avatar-state-success:before,
figure.avatar.avatar-sm.avatar-state-danger:before,
figure.avatar.avatar-sm.avatar-state-warning:before,
figure.avatar.avatar-sm.avatar-state-info:before,
figure.avatar.avatar-sm.avatar-state-secondary:before,
figure.avatar.avatar-sm.avatar-state-light:before,
figure.avatar.avatar-sm.avatar-state-dark:before {
  width: .8rem;
  height: .8rem;
}

figure.avatar.avatar-lg {
  height: 3.8rem;
  width: 3.8rem;
}

figure.avatar.avatar-lg .avatar-title {
  font-size: 29px;
}

figure.avatar.avatar-lg.avatar-state-primary:before,
figure.avatar.avatar-lg.avatar-state-success:before,
figure.avatar.avatar-lg.avatar-state-danger:before,
figure.avatar.avatar-lg.avatar-state-warning:before,
figure.avatar.avatar-lg.avatar-state-info:before,
figure.avatar.avatar-lg.avatar-state-secondary:before,
figure.avatar.avatar-lg.avatar-state-light:before,
figure.avatar.avatar-lg.avatar-state-dark:before {
  width: 1.2rem;
  height: 1.2rem;
  right: -1px;
}

figure.avatar.avatar-xl {
  height: 6.1rem;
  width: 6.1rem;
}

figure.avatar.avatar-xl .avatar-title {
  font-size: 39px;
}

figure.avatar.avatar-xl.avatar-state-primary:before,
figure.avatar.avatar-xl.avatar-state-success:before,
figure.avatar.avatar-xl.avatar-state-danger:before,
figure.avatar.avatar-xl.avatar-state-warning:before,
figure.avatar.avatar-xl.avatar-state-info:before,
figure.avatar.avatar-xl.avatar-state-secondary:before,
figure.avatar.avatar-xl.avatar-state-light:before,
figure.avatar.avatar-xl.avatar-state-dark:before {
  width: 1.2rem;
  height: 1.2rem;
  top: 2px;
  right: 7px;
}

figure.avatar.avatar-state-primary,
figure.avatar.avatar-state-success,
figure.avatar.avatar-state-danger,
figure.avatar.avatar-state-warning,
figure.avatar.avatar-state-info,
figure.avatar.avatar-state-secondary,
figure.avatar.avatar-state-light,
figure.avatar.avatar-state-dark {
  position: relative;
}

figure.avatar.avatar-state-primary:before,
figure.avatar.avatar-state-success:before,
figure.avatar.avatar-state-danger:before,
figure.avatar.avatar-state-warning:before,
figure.avatar.avatar-state-info:before,
figure.avatar.avatar-state-secondary:before,
figure.avatar.avatar-state-light:before,
figure.avatar.avatar-state-dark:before {
  content: "";
  position: absolute;
  display: block;
  width: .8rem;
  height: .8rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  top: -2px;
  right: -2px;
  border: 3px solid white;
}

figure.avatar.avatar-state-primary:before {
  background: #2dc5f3;
}

figure.avatar.avatar-state-success:before {
  background: #0abb87;
}

figure.avatar.avatar-state-danger:before {
  background: #fd397a;
}

figure.avatar.avatar-state-warning:before {
  background: #ffb822;
}

figure.avatar.avatar-state-info:before {
  background: #00bcd4;
}

figure.avatar.avatar-state-secondary:before {
  background: dimgray;
}

figure.avatar.avatar-state-light:before {
  background: #e6e6e6;
}

figure.avatar.avatar-state-dark:before {
  background: #3a3f51;
}

.avatar-group {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 2.2rem;
}

.avatar-group figure.avatar {
  border: 2px solid white;
  margin-right: -1rem !important;
}

.avatar-group figure.avatar:last-child {
  margin-right: 0;
}

.avatar-group figure.avatar:hover {
  position: relative;
  z-index: 1;
}

.modal-content {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  overflow: hidden;
}

.modal-content .modal-header {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 30px;
  background-color: #2dc5f3;
  color: white;
  border-bottom: none;
}

.modal-content .modal-header .modal-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 13px;
}

.modal-content .modal-header .modal-title i,
.modal-content .modal-header .modal-title figure.avatar {
  margin-right: .7rem;
  font-size: 17px;
}

.modal-content .modal-header .modal-title svg {
  width: 18px;
  height: 18px;
}

.modal-content .modal-header .modal-title+button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-shadow: none;
  font-size: 12px;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  color: white;
  opacity: 1;
}

.modal-content .modal-body {
  padding: 30px;
}

.modal-content .modal-footer {
  border-top: none;
  padding: 30px;
  padding-top: 0;
}

body:not(.no-blur-effect).modal-open .layout {
  -webkit-filter: blur(1.5px);
  -moz-filter: blur(1.5px);
  -o-filter: blur(1.5px);
  -ms-filter: blur(1.5px);
  filter: blur(1.5px);
}

body:not(.no-blur-effect) .modal.fade .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(0.95);
  -moz-transform: translate(0, 0) scale(0.95);
  -ms-transform: translate(0, 0) scale(0.95);
  -o-transform: translate(0, 0) scale(0.95);
  transform: translate(0, 0) scale(0.95);
}

body:not(.no-blur-effect) .modal.show .modal-dialog.modal-dialog-zoom {
  -webkit-transform: translate(0, 0) scale(1);
  -moz-transform: translate(0, 0) scale(1);
  -ms-transform: translate(0, 0) scale(1);
  -o-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
}

.call {
  text-align: center;
}

.call .modal-content .modal-body {
  padding: 50px 0;
}

.call .action-button {
  margin-top: 3rem;
}

.call .action-button .btn {
  width: 70px;
  height: 70px;
  margin: 0 15px;
}

.call .action-button .btn svg {
  width: 28px;
  height: 28px;
}

.nav.nav-tabs {
  border: none;
}

.nav.nav-tabs .nav-item {
  margin: 0;
}

.nav.nav-tabs .nav-item:first-child {
  margin-left: 0;
}

.nav.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.nav.nav-tabs .nav-item .nav-link {
  border: none;
  color: black;
  border-bottom: 2px solid transparent;
  padding: 10px;
  padding-top: 0;
}

.nav.nav-tabs .nav-item .nav-link:hover {
  color: #969696;
}

.nav.nav-tabs .nav-item .nav-link.active {
  color: #2dc5f3;
  border-bottom-color: #2dc5f3;
}

.badge {
  padding: 5px 10px;
  font-size: 11px;
}

.badge.badge-success {
  background: #0abb87;
}

.badge.badge-danger {
  background: #fd397a;
}

.badge.badge-secondary {
  background: dimgray;
}

.badge.badge-info {
  background: #00bcd4;
}

.badge.badge-warning {
  background: #ffb822;
}

.badge.badge-dark {
  background: #3a3f51;
}

.badge.badge-primary {
  background: #2dc5f3;
}

.badge.badge-light {
  background: #e6e6e6;
}

.btn {
  position: relative;
}

.btn .badge {
  padding: 2px 6px;
  right: 7px;
  top: -7px;
  position: absolute;
}

nav.navigation {
  background: white;
  border-right: 1px solid #ebebeb;
}

nav.navigation .nav-group {
  width: 60px;
}

nav.navigation ul {
  height: 100vh;
  height: -webkit-calc(var(--vh, 1vh) * 100);
  height: -moz-calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

nav.navigation ul li {
  list-style: none;
}

nav.navigation ul li:not(.logo) {
  margin: 5px;
}

nav.navigation ul li:not(.logo)>a {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

nav.navigation ul li>a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 0;
  color: #828282;
  position: relative;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  -moz-transition: all .3s;
  transition: all .3s;
}

nav.navigation ul li>a svg {
  /* width: 24px;
  height: 18px; */
}

nav.navigation ul li>a .badge {
  display: block;
  padding: 2px;
  top: 5px;
  right: 5px;
  position: absolute;
   /*-webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
   */
}

nav.navigation ul li>a.active {
  background-color: #e6e6e6;
  color: #2dc5f3;
}

nav.navigation ul li>a:hover,
nav.navigation ul li>a:focus {
  color: #2dc5f3;
}

nav.navigation ul li>a i {
  font-size: 19px;
}

nav.navigation ul li.logo a {
  margin-bottom: 1rem;
  height: 80px;
}

nav.navigation ul li.logo a svg {
  width: 30px;
  height: 30px;
}

nav.navigation ul li.logo a svg path {
  fill: #2dc5f3;
}

nav.navigation ul li.brackets {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.list-group-item .badge {
  font-size: 10px;
  font-weight: normal;
  padding: 5px;
  margin: 2px;
}


.chat .chat-body .messages .message-item .message-content hr {
  margin: 5px;
  border-style: dashed;
}


body.form-membership {
  padding: 3rem 0;
}

body.form-membership .form-wrapper {
  border: 1px solid #e6e6e6;
  background-color: white;
  padding: 3rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 430px;
  margin: 50px auto;
  text-align: center;
}

body.form-membership .form-wrapper .logo {
  background-color: white;
  width: 100px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100px;
  text-align: center;
  margin-bottom: 2rem;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

body.form-membership .form-wrapper h5 {
  text-align: center;
  margin-bottom: 2rem;
}

body.form-membership .form-wrapper form .form-control {
  margin-bottom: 1.5rem;
}

body.form-membership .form-wrapper hr {
  margin: 2rem 0;
}

.alert {
  border: none;
}

.enjoyhint {
  z-index: 9999;
}

.enjoyhint_next_btn,
.enjoyhint_skip_btn,
.enjoyhint_close_btn {
  border-color: #2dc5f3;
  color: white;
}

.enjoyhint_next_btn:hover,
.enjoyhint_next_btn:active,
.enjoyhint_next_btn:focus,
.enjoyhint_skip_btn:hover,
.enjoyhint_skip_btn:active,
.enjoyhint_skip_btn:focus,
.enjoyhint_close_btn:hover,
.enjoyhint_close_btn:active,
.enjoyhint_close_btn:focus {
  border-color: #2dc5f3;
  background-color: #2dc5f3;
}

.input-group svg {
  width: 16px;
  height: 16px;
}

.input-group .input-group-text {
  font-size: 14px;
}

.tooltip .arrow {
  display: none;
}

.tooltip .tooltip-inner {
  background-color: rgba(0, 0, 0, 0.5);
}

.sticky-top {
  z-index: auto;
}

.tab-content .form-item {
  margin-bottom: .8rem;
}

.layout {
  height: 100vh;
  height: -webkit-calc(var(--vh, 1vh) * 100);
  height: -moz-calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.layout .content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.layout .content .sidebar-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.layout .content .sidebar-group .sidebar {
  background: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
  width: 335px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.layout .content .sidebar-group .sidebar:not(.active) {
  display: none;
}

.layout .content .sidebar-group .sidebar>header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 30px;
  height: 80px;
  font-weight: 600;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.layout .content .sidebar-group .sidebar>header>span {
  font-size: 22px;
}

.layout .content .sidebar-group .sidebar>header ul {
  margin-bottom: 0;
}

.layout .content .sidebar-group .sidebar>header ul li {
  margin-bottom: 0;
}

.layout .content .sidebar-group .sidebar>form {
  /* padding: 10px; */
}

.layout .content .sidebar-group .sidebar>form .form-control {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  /* height: auto; */
  border: 1px solid #e6e6e6;
  /* padding: 10px 15px; */
}

.layout .content .sidebar-group .sidebar .sidebar-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  border-top: 1px solid #ebebeb;
}

.layout .content .sidebar-group .sidebar .list-group-item {
  padding:0;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
}

.layout .content .sidebar-group .sidebar .list-group-item.open-chat {
  background-color: #F5D0C2;
}

.layout .content .sidebar-group .sidebar .list-group-item.open-chat .users-list-body p {
  font-weight: 600;
  color: #646464;
}

.layout .content .sidebar-group .sidebar .list-group-item figure {
  /* margin-right: 0.5rem; */
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  min-width: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body>div:first-child {
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body h5 {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: .2rem;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body p {
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  color: #969696;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action {
  padding-left: 15px;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action [data-toggle="dropdown"] i {
  font-size: 18px;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action .new-message-count {
  width: 23px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 0;
  font-size: 13px;
  height: 23px;
  background-color: #2dc5f3;
  color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-left: auto;
}

.layout .content .sidebar-group .sidebar .list-group-item .users-list-body .users-list-action .action-toggle {
  opacity: 0;
  text-align: right;
}

.layout .content .sidebar-group .sidebar .list-group-item:hover .users-list-action .action-toggle {
  opacity: 1;
}

.layout .content .chat {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.layout .content .chat .chat-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 15px;
}

.layout .content .chat .chat-header .chat-header-user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout .content .chat .chat-header .chat-header-user figure {
  margin-right: 1rem;
}

.layout .content .chat .chat-header .chat-header-user h5 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
  line-height: 1;
}

.layout .content .chat .chat-header .chat-header-action {
  min-width: 190px;
}

.layout .content .chat .chat-header .chat-header-action ul {
  margin-bottom: 0;
}

.layout .content .chat .chat-header .chat-header-action ul>li>a {
  font-size: 16px;
}

.layout .content .chat .chat-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 30px;
}

.layout .content .chat .chat-body:not(.no-message) {
  overflow: auto;
}

.layout .content .chat .chat-body.no-message {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.layout .content .chat .chat-body.no-message .no-message-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.layout .content .chat .chat-body.no-message .no-message-container svg {
  width: 50px;
  height: 50px;
  stroke: #2dc5f3;
  fill: #e6e6e6;
}

.layout .content .chat .chat-body.no-message .no-message-container p {
  font-size: 16px;
  color: #828282;
}

.layout .content .chat .chat-body .messages {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.layout .content .chat .chat-body .messages .message-item {
  max-width: 90%;
  margin-bottom: 1rem;
}

.layout .content .chat .chat-body .messages .message-item:last-child {
  margin-bottom: 0;
}

.layout .content .chat .chat-body .messages .message-item .message-avatar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout .content .chat .chat-body .messages .message-item .message-avatar .avatar {
  margin-right: 1rem;
}

.layout .content .chat .chat-body .messages .message-item .message-avatar h5 {
  font-size: 15px;
  margin-bottom: 0;
}

.layout .content .chat .chat-body .messages .message-item .time {
  color: #828282;
  margin-top: 2px;
  font-style: italic;
  font-size: 12px;
}

.layout .content .chat .chat-body .messages .message-item .time .ti-double-check:before,
.layout .content .chat .chat-body .messages .message-item .time .ti-double-check:after {
  content: "\e64c";
  font-family: 'themify';
}

.layout .content .chat .chat-body .messages .message-item .time .ti-double-check:after {
  margin-left: -4px;
}

.layout .content .chat .chat-body .messages .message-item .message-content {
  background: #F3F6F9;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  padding: 10px;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  word-break: break-word;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file .file-icon {
  font-size: 24px;
  margin-right: 1rem;
  color: #828282;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file ul {
  margin-top: 3px;
  margin-bottom: 0;
}

.layout .content .chat .chat-body .messages .message-item .message-content.message-file ul li.list-inline-item {
  margin-left: 0;
  margin-right: .5rem;
}

.layout .content .chat .chat-body .messages .message-item.outgoing-message {
  margin-left: auto;
}

.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-avatar {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.layout .content .chat .chat-body .messages .message-item.outgoing-message .message-content {
  background-color: #DEF0FE;
  /* color: white; */
}

.layout .content .chat .chat-body .messages .messages-divider {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.layout .content .chat .chat-body .messages .messages-divider:before {
  content: attr(data-label);
  display: block;
  position: absolute;
  top: -8px;
  letter-spacing: .5px;
  font-size: 11px;
  padding: 2px 8px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #e6e6e6;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.layout .content .chat .chat-footer {
  border-top: 1px solid #e6e6e6;
  padding: 17px 30px;
  /* padding-bottom: 50px; */
}

.layout .content .chat .chat-footer form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout .content .chat .chat-footer form .form-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}

.layout .content .chat .chat-footer form .form-buttons .btn {
  margin-left: 1rem;
}

.layout .content .chat+.sidebar-group {
  margin-right: 0px;
}

.layout .content .chat+.sidebar-group .sidebar {
  margin-right: 0px;
}


@media screen and (max-width: 576px) {

  body:not(.rtl) .sidebar-group,
  body .sidebar-group {
    width: 100%;
  }

  body.form-membership {
    padding: 20px;
  }

  body.form-membership .form-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    width: 100% !important;
    margin-bottom: 20px;
  }
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}
.separator span {
  color: #6b6969;
  padding-right: 5px;
}
.separator .sender {
  font-weight: bold;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  /* border-bottom: 1px dashed #bdbdbd; */
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.active-chat {
  background-color: #DEF0FE !important;
}
.labels {
  /* position: absolute;
  display: flex; */
}
 .labels * {
  height: 56.5px;
  width: 7px;
  border-radius: 25px;
  padding-bottom: 2px;
}

.labels .vip{
  background-color: red;  
}
.labels .new{
  background-color: #0abb87;
}
.item-vip{
  /* border-left: 5px solid red !important; */
}

.item-sliver{
  /* border-left: 5px solid #0abb87 !important; */
}
.list-group-item .avatar, .list-group-item .users-list-body {
  margin: .25rem 0.25rem;
}